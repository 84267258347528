import React from 'react';
import { Col } from 'antd';
import Layout from '../components/Layout';
import ProductsBanner from '../components/ProductsBanner';
import VideoTextCard from '../components/VideoTextCard';
import CDPContentList from '../components/CDPContentList';
import TitleAndVerticalBoxes from '../components/TitledVerticalBoxes';
import VerticalBox from '../components/VerticalBox';
import HomeLogos from '../components/HomeLogos';
import BlockIconText from '../components/BlockIconText';
import CdpBanner from '../images/data-banner-image.png';

export default () => (
  <Layout current="products" subCurrent="cdp">
    <ProductsBanner
      title="Data Hub 客户数据平台"
      description="CMC数字化底座产品，一款基于多年业务最佳实践沉淀出的低代码CDP。跨平台整合全渠道客户数据，输出精准客户洞察，指导营销决策并高实时性地支撑用户运营，实现企业数据资产的高效变现与增值，让您快速成为数智化转型的践行者。"
      img={CdpBanner}
      imgWidth={466}
      imgHeight={350}
      video="https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/videos/datahub-20220114.mov"
      videoId="cl-datahub"
      cover="https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/video-cover-data-20220114.png"
    />
    <VideoTextCard
      title="数据驱动增长"
      cover="//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/dmhub-cdp-cover-20220325.png"
      video="//convertlab-website.oss-cn-hangzhou.aliyuncs.com/videos/dmhub-cdp-20220325.mp4"
      // video="https://cltemplate.oss-cn-hangzhou.aliyuncs.com/video/dmhub-cdp.mp4"
    >
      <BlockIconText
        content="完善的CDP实施方案：从数据链接管理到数据整合、洞察和应用，持续沉淀数据资产价值"
        color="blue"
      />
      <BlockIconText
        content="湖仓一体的架构设计思路：轻松应对和处理各种实时、离线数据"
        color="green"
      />
      <BlockIconText
        content="数据资产整合：会员、事件、订单、商品、渠道，并支持自定义对象"
        color="blue"
      />
      <BlockIconText
        content="数据清洗整理：丰富的标签体系、精准的客户360°画像、详尽的业务指标体系"
        color="green"
      />
      <BlockIconText
        content="丰富的数据应用：One ID、会员档案、客群筛选、客户画像、形成DMP人群包"
        color="blue"
      />
      <BlockIconText
        content="数据安全管家：灵活的去标识方案，清晰的溯源链路，确保每个数据都被合理使用"
        color="green"
      />
    </VideoTextCard>
    <CDPContentList />
    <TitleAndVerticalBoxes title="数据应用" subTitle="业务价值和营销场景">
      <Col lg={8} md={12} xs={12}>
        <VerticalBox iconIndex={9} title="用户画像" description="清晰精准地了解客户的行为和特征" />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <VerticalBox iconIndex={10} title="精准营销" description="有效支持千人千面的个性化精准营销" />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <VerticalBox iconIndex={11} title="DMP人群包" description="输出精准的广告投放DMP人群包" />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <VerticalBox iconIndex={13} title="One ID" description="多渠道重复客户归并，避免重复营销触达" />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <VerticalBox iconIndex={14} title="商业BI分析" description="可视化数据洞察，有效掌握业务全景" />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <VerticalBox iconIndex={101} title="数据安全合规" description="无缝集成Convertlab CPM工具" />
      </Col>
    </TitleAndVerticalBoxes>
    <HomeLogos />
  </Layout>
);
