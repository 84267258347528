import React from 'react';
import Container from './Container';
import ImageText from './ImageText';
import ImageTextDescription from './ImageTextDesctiption';
import Ma1 from '../images/ma-1.png';
import Cdp1 from '../images/cdp-1.svg';
import Ma2 from '../images/ma-2.png';

export default () => {
  return (
    <div className="content-list-wrapper">
      <div>
        <section className="image-text-section">
          <Container>
            <ImageText image={Ma1} imgWidth={417} imgHeight={335} title="数据链接">
              <ImageTextDescription
                label="打通营销触点"
                content="网站、微信，App、短信、邮件、广告、小程序"
              />
              <ImageTextDescription
                label="企业应用系统"
                content="直播系统、会员系统、CRM/ERP/OA、客服系统"
              />
              <ImageTextDescription
                label="线上交易渠道"
                content="淘宝、天猫、京东、支付宝生活号、自建商城"
              />
              <ImageTextDescription
                label="线下交易渠道"
                content="门店、POS、一物一码、导购、卡券"
              />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container>
            <ImageText image={Cdp1} imgWidth={445} imgHeight={315} title="数据整合" position="right">
              <ImageTextDescription label="实时处理能力" content="亿级海量数据的稳定计算能力" />
              <ImageTextDescription label="数据清洗" content="异常数据过滤、脏数据清洗和较验" />
              <ImageTextDescription label="数据治理" content="升级可拓展体系与数据血缘支持，确保数据完整有效可追查" />
              <ImageTextDescription label="数据合并" content="自动化的ID Mapping和One-ID体系，单租户多工作区让数据储存和处理更安全、迁移更便捷" />
              <ImageTextDescription label="数据分析" content="AI Hub人群聚类智能分析和模型验算" />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container>
            <ImageText image={Ma2} imgWidth={447} imgHeight={302} title="数据洞察">
              <ImageTextDescription
                label="Plus+标签体系"
                content="提供极度灵活的自定义标签设计能力，支持Convertlab独家知识产权的GDQL标签灵活查询"
              />
              <ImageTextDescription
                label="形成用户画像"
                content="通过标签、属性、来源、事件、活跃度等指标形成精准360°客户画像"
              />
              <ImageTextDescription
                label="精准客户分层"
                content="通过客户画像背后的多级维度，智能化圈群分组"
              />
            </ImageText>
          </Container>
        </section>
      </div>
    </div>
  );
};
